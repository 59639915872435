<template>
  <div>
    <div class="form-group">
      <b>Numele tipului de instituție</b>

      <input type="text" class="vInput" v-model="institutionType.name" />
    </div>

    <div class="form-group">
      <b>Tipuri de structuri organizatorice</b>

      <v-select
        v-model="institutionType.organizationalStructureType"
        label="name"
        :multiple="true"
        :options="organizationalStructureTypeTypes"
      >
      </v-select>
      <!--  <div
        class="verbal-process-container"
        v-for="(item, index) of institutionType.organizationalStructureType"
      >
        <i
          @click="deleteProvider(index)"
          v-tooltip="'Șterge furnizor'"
          class="fas fa-minus-circle delete-provider-icon"
          style="cursor: pointer;  color: red"
        ></i>
        <div class="verbal-process-group">
          <div class="form-group noMB">
            <div>
              <b>CUI furnizor</b>
            </div>
            <div class="RForm">
              <input
                type="text"
                class="vInput"
                v-model="item.institutionProviderCui"
              />
              <i @click="getByCui(index)" class="fas fa-search"></i>
            </div>
          </div>
          <div class="verbal-process">
            <b>Nume furnizor</b>
            <input
              type="text"
              class="vInput form-disabled"
              v-model="item.institutionProviderName"
              :disabled="true"
            />
          </div>

          <div class="verbal-process">
            <b>Valoare totală fără TVA</b>
            <CustomInput
              v-model="item.totalValueWithoutTva"
              @input="updateTotalValueWithTva(index)"
            ></CustomInput>
          </div>

          <div class="verbal-process">
            <b>Cotă TVA</b>
            <input
              min="1"
              max="100"
              type="text"
              class="vInput procent"
              v-model="item.tvaValue"
              @input="($event) => updateTva($event, index)"
            />
          </div>

          <div class="verbal-process">
            <b>Valoare totală cu TVA</b>
            <CustomInput
              v-model="item.totalValueWithTva"
              :disabled="true"
            ></CustomInput>
          </div>
        </div>
      </div>
    </div> -->
      <!--     <div
      v-if="institutionType.organizationalStructureType.length < 10"
      style="display: flex; justify-content: flex-end;"
      @click="addProvider"
    >
      <i
        v-tooltip="'Adaugă furnizor'"
        class="fas fa-plus-circle"
        style="cursor: pointer;  color: #39f"
      ></i>
    </div> -->
    </div>
  </div>
</template>
<script>
import CustomInput from "@/components/CustomInput";
import { ORGANIZATIONAL_STRUCTURE_TYPE } from "../api.js";
export default {
  components: { CustomInput },

  props: {
    initial: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      organizationalStructureTypeTypes: [],
      institutionType: {
        name: "",
        organizationalStructureType: [],
      },
    };
  },

  watch: {
    institutionType: {
      handler(val) {
        this.$emit("input", {
          ...val,
        });
      },
      deep: true,
      immediate: true,
    },
    deep: true,
    immediate: true,
  },
  computed: {},

  methods: {
    initStructureType() {
      ORGANIZATIONAL_STRUCTURE_TYPE.get(1, 500).then((res) => {
        this.organizationalStructureTypeTypes = res.data.result.filter(
          (el) =>
            !this.institutionType.organizationalStructureType.find(
              (k) => el.id == k.id
            )
        );
        console.log(res);
      });
    },
    getByCui(index) {
      const institutionProviderCui = this.institutionType
        .organizationalStructureType[index].institutionProviderCui;

      const error = (msg) => {
        this.$toastr.e(msg || "Furnizorul nu a fost gasit.");
        this.institutionType.organizationalStructureType[
          index
        ].institutionProviderName = "";
        this.setLoad(false);
      };

      this.setLoad(true);

      INSTITUTION_PROVIDERS.getByCui(institutionProviderCui)
        .then((res) => {
          console.log(res);
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.institutionType.organizationalStructureType[
              index
            ].institutionProviderName = res.data.name;
            this.setLoad(false);
          } else {
            error(res?.data?.response || null);
          }
        })
        .catch(error);
    },
  },
  created() {
    console.log({initial: this.initial});
    
    if (this.initial) {
      this.institutionType = this.initial;
    }

    this.initStructureType();
  },
};
</script>
<style lang="scss">
.verbal-process-group {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-group {
    flex: 1;
  }
}
.verbal-process-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 15px 10px;
  position: relative;
  margin-bottom: 10px;
  .delete-provider-icon {
    display: none;
    position: absolute;
    top: -8px;
    right: -7px;
    background-color: white;
  }
}

.verbal-process-container:hover {
  .delete-provider-icon {
    display: initial;
  }
}
</style>
